<template>
  <select
    ref="inputField"
    :class="['form-select', { float: float }]"
    :disabled="!editable"
    v-model="lValue"
  >
    <option :value="null">{{ noneText }}</option>
    <option v-for="option in options" :key="option.id" :value="option.id">
      {{ option.value }}
    </option>
  </select>
</template>

<script>
export default {
  name: "EditableSelect",
  props: {
    value: [Number, String],
    options: Array,
    editable: {
      type: Boolean,
      default: false,
    },
    float: Boolean,
  },
  computed: {
    lValue: {
      get: function () {
        if (this.options) {
          return this.value;
        }
        return null;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    noneText: function () {
      if (this.options) {
        return " --- ";
      }
      return "Загрузка...";
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  &.float {
    background-color: $main-2;
    &:disabled {
      background-color: $main-1;
      border: 2px solid $main-2;
      border-radius: 6px;
    }
  }
  &:not(.float) {
    background-color: $main-1;
    &:disabled {
      background-color: $main-2;
      border: 2px solid $main-1;
      border-radius: 6px;
    }
  }
}
</style>
