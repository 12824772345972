import { createLaborSchema } from "@/services/references/labor/schemas";

export const parse_labor_to_create = (value) => {
  let data = Object.assign({}, createLaborSchema);
  data.code = value.code;
  data.status_id = value.status_id;
  data.enproRate.value = value.enproRate;
  data.WorkIdentifiedObject.IdentifiedObject.name = value.name;
  data.WorkIdentifiedObject.IdentifiedObject.description = value.description;
  return data;
};

export const parse_labor_to_update = (value) => {
  let data = Object.assign({}, value);
  data.status_id = value.status.id;
  return data;
};
