export let createLaborSchema = {
  WorkIdentifiedObject: {
    id: 0,
    IdentifiedObject: {
      id: 0,
      name: "",
      description: "",
      names: [],
    },
  },
  code: "",
  enproRate: {
    value: 0,
  },
  status_id: 0,
};
