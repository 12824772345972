import axios from "@/plugins/axios";
import Vue from "vue";

/*
Глобальные справочники
 */

//Список статусов
export const API_StatusList = () => {
  return axios(`/status`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (item) => ({
        id: item.id,
        value: item.value,
      }))
    )
    .catch((error) => {
      throw error;
    });
};

//Список статусов WorkTask
export const API_WorkTaskStatusList = () => {
  return axios(`/status?kind=WorkTask`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (item) => ({
        id: item.id,
        value: item.ru_value,
      }))
    )
    .catch((error) => {
      throw error;
    });
};

//Список единиц измерения
export const API_SymbolList = () => {
  return axios(`/unitSymbols`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (item) => ({
        id: item.id,
        value: item.literal,
      }))
    )
    .catch((error) => {
      throw error;
    });
};

//Список мультипликаторов
export const API_MultiplierList = () => {
  return axios(`/unitMultiplier`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (item) => ({
        id: item.id,
        value: item.literal,
      }))
    )
    .catch((error) => {
      throw error;
    });
};

//Список приоритетов
export const API_PriorityEnum = () => {
  return axios(`/priority`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (item) => ({
        id: item.id,
        value: item.justification,
      }))
    )
    .catch((error) => {
      throw error;
    });
};

//Типы затрат
export const API_CostType = () => {
  return axios(`/costType`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) =>
      Array.from(response.data.data, (item) => ({
        id: item.id,
        value: item.WorkIdentifiedObject.IdentifiedObject.name,
      }))
    )
    .catch((error) => {
      throw error;
    });
};

export const API_ExcelModels = () => {
  return axios(`/loadExcel/models`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      let parseData = [];
      response.data.data.forEach((element) => {
        let parseElement = [];
        element.items.forEach((item) => {
          parseElement.push({
            id: item.modelName,
            value: item.title,
            template: item.template,
          });
        });
        parseData.push({
          group: element.group,
          items: parseElement,
        });
      });
      return parseData;
    })
    .catch((error) => {
      throw error;
    });
};

export const API_ExcelImport = (modelName, file) => {
  return axios(`/loadExcel/models/${modelName}`, {
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
    },
    data: file,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const API_AssetContainers = () => {
  const types = [
    117110000000000000, 117120000000000000, 117130000000000000,
    117140000000000000, 117150000000000000, 116030000000000000,
    116040000000000000, 116010000000000000, 103030201000000000,
    103030202000000000, 103030203000000000, 103030204000000000,
    103030205000000000, 103030206600000000, 103030207700000000,
    103030208800000000,
  ];
  return axios(
    "assetContainers?enproAssetType=top" +
      types.map((i) => "&types[]=" + i).join(""),
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  )
    .then((response) =>
      Array.from(response.data.data, (assetContainer) => ({
        id: assetContainer.id,
        locationId: assetContainer.location.id,
        name: assetContainer.name,
      })).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    )
    .catch((error) => {
      throw error;
    });
};

export const API_getAssetContainer = (assetContainerId) => {
  return axios(`assetContainers/${assetContainerId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  }).then((response) => {
    if (response.data.data) {
      let item = response.data.data;
      return {
        id: item.id,
        locationId: item.location.id,
        name: item.name,
        assets: Array.from(item.assets,
          (asset) => ({
            id: asset.location.id,
            type: asset.location.type,
            name: asset.location.name,
            points: Array.from(asset.location.positionPoints, (p) => [p.xPosition, p.yPosition,]).sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            }),
          })
        ),
      }
    } else {
      return undefined;
    }
  }).catch((error) => {
    Vue.notify({
      type: "error",
      text: "Не удалось получить данные модели assetContainers",
    });
    throw error;
  });
};

export const API_Locations = () => {
  const types = [
    117110000000000000, 117120000000000000, 117130000000000000,
    117140000000000000, 117150000000000000, 116030000000000000,
    116040000000000000, 116010000000000000, 103030201000000000,
    103030202000000000, 103030203000000000, 103030204000000000,
    103030205000000000, 103030206600000000, 103030207700000000,
    103030208800000000,
  ];
  return axios(
    "assetContainers?enproAssetType=top" +
      types.map((i) => "&types[]=" + i).join(""),
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      Vue.notify({
        type: "error",
        text: "Не удалось получить данные модели assetContainers",
      });
      throw error;
    });
};

export const API_assets_by_location_name = (name) => {
  return axios(`assetContainers?filterName=${name}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      if (response.data.data.length > 0) {
        return response.data.data[0].assets.sort((a, b) => {
          if (a.location.name < b.location.name) {
            return -1;
          }
          if (a.location.name > b.location.name) {
            return 1;
          }
          return 0;
        });
      }
      return [];
    })
    .catch((error) => {
      Vue.notify({
        type: "error",
        text: "Не удалось получить данные модели assetContainers",
      });
      throw error;
    });
};
