import axios from "@/plugins/axios";
import {
  parse_labor_to_create,
  parse_labor_to_update,
} from "@/services/references/labor/parsers";

/**
 * Список "Часовые тарифные ставки труда"
 * @return {Promise<LaborObject[]>}
 */
export const API_labor_list = () => {
  return axios("/cULaborCode?perPage=5000", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Просмотр элемента "Часовые тарифные ставки труда"
 * @param  {number} laborId
 * @return {Promise<LaborObject>}
 */
export const API_labor_view = (laborId) => {
  return axios(`/cULaborCode/${laborId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Обновление элемента "Часовые тарифные ставки труда"
 * @param {number} laborId
 * @param {Object} value
 * @return {Promise<LaborObject>}
 */
export const API_labor_update = (laborId, value) => {
  return axios(`/cULaborCode/${laborId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parse_labor_to_update(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание "Часовые тарифные ставки труда"
 * @param {Object} value
 * @return {Promise<LaborObject>}
 */
export const API_labor_create = (value) => {
  return axios(`/cULaborCode/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_labor_to_create(value),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление элемента "Часовые тарифные ставки труда"
 * @param  {number} laborId
 * @return {Promise<boolean>}
 */
export const API_labor_delete = (laborId) => {
  return axios(`/cULaborCode/${laborId}`, {
    method: "DELETE",
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
